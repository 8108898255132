<template>
  <el-form ref="form" :model="form" :rules="rules" label-position="left">
    <el-form-item style="margin-top: 5px" prop="username">
      <span class="qz-svg-container svg-container-admin">
        <i class="el-icon-user"></i>
        用户名
      </span>
      <el-input
        v-model.trim="form.username"
        v-focus
        placeholder="请输入您的用户名"
        tabindex="1"
        type="text"
        @keyup.enter.native="handleLogin"
      />
    </el-form-item>
    <el-form-item prop="password">
      <span class="qz-svg-container">
        <i class="el-icon-lock"></i>
        密&nbsp;&nbsp;&nbsp;&nbsp;码
      </span>
      <el-input
        :key="passwordType"
        ref="password"
        v-model.trim="form.password"
        :type="passwordType"
        tabindex="2"
        @keyup.enter.native="handleLogin"
        placeholder="请输入密码"
      />
    </el-form-item>
    <el-form-item v-if="application.enable_captcha == '1'" prop="verifiyCode">
      <span class="qz-svg-container">
        <i class="iconfont icon-verification-code"></i>
        验证码
      </span>
      <el-input
        ref="captcha_value"
        v-model.trim="form.captcha_value"
        type="text"
        tabindex="3"
        maxlength="6"
        placeholder="请输入验证码"
        @keyup.enter.native="handleLogin"
        class="verifiy-code-input"
      />
      <img
        class="gp-image qz-code-img"
        v-if="codeUrl"
        :src="codeUrl"
        @click="getCode"
      />
    </el-form-item>
    <div class="qz-remember-password">
      <el-checkbox v-model="form.rememberPSW">记住密码</el-checkbox>
    </div>
  </el-form>
</template>

<script>
import { login } from "@/mixins/login.js";
import { mapGetters } from "vuex";

export default {
  mixins: [login],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      passwordType: "password",
    };
  },
  computed: {
    ...mapGetters({
      application: "application/application",
    }),
  },
  watch: {
    "application.enable_captcha"() {
      if (this.application.enable_captcha == "1") {
        this.getCode();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qz-svg-container {
  position: absolute;
  top: 14px;
  left: 5px;
  z-index: $base-z-index;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-show-password {
  position: absolute;
  top: 14px;
  right: 25px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-code-img {
  position: absolute;
  right: 0;
  top: 14px;
  width: 120px;
  height: 40px;
  cursor: pointer;
}

.qz-remember-password {
  margin-bottom: 20px;
  display: flex;
  margin-left: 10px;
}
</style>
