<template>
  <div class="qz-login-container">
    <div class="qz-header-title">
      <!-- <el-image
                class="qz-header-logo"
                :src="require('@/assets/home/logo_30_39.png')"
            ></el-image> -->
      <div class="qz-title">{{ title }}</div>
    </div>
    <el-row class="h-100">
      <!-- <el-col :xs="1" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="22" :sm="20" :md="20" :lg="16" :xl="12"> -->
      <div
        class="qz-login-form row school"
        :style="{ minWidth: screenWidth > 567 ? '600px' : '' }"
      >
        <div class="left-panel" :class="{ disabled: scanCodeLogin == 4 }">
          <el-tabs v-model="loginType">
            <el-tab-pane v-if="passwordLogin" label="账号密码" name="1">
              <login-form-by-password
                ref="loginFormByP"
                @change="loadingChange"
              >
              </login-form-by-password>
            </el-tab-pane>
            <el-tab-pane v-if="phoneCodeLogin" label="短信登录" name="2">
              <login-form-by-msg ref="loginFormByMsg"></login-form-by-msg>
            </el-tab-pane>
          </el-tabs>
          <el-button
            v-if="passwordLogin || phoneCodeLogin || loginType == 4"
            :loading="loading"
            class="qz-login-btn"
            type="primary"
            :round="false"
            @click="handleLogin"
          >
            登录
          </el-button>

          <!-- <div v-show="(passwordLogin || phoneCodeLogin) && loginType != '4'" class="qz-remember-password">
              <el-checkbox v-model="agreement">
                阅读并同意<a class="protocol">学工系统用户协议</a>及<a
                  class="protocol"
                  >用户隐私协议</a
                >
              </el-checkbox>
            </div>

            <div v-if="application.enable_find_password == '1'" class="forgot-password">忘记密码？<a>这里找回</a></div> -->
        </div>
        <div class="line" v-if="qrcodeLogin"></div>
        <div class="right-panel" v-if="qrcodeLogin">
          <third-login type="code" v-show="scanCodeLogin != 4"></third-login>
          <div
            v-if="qrcodeLogin"
            class="code-content"
            v-show="scanCodeLogin == 4"
          >
            <el-image
              class="code-img"
              :src="require('@/assets/template/v2_rnzrwk.png')"
            ></el-image>
          </div>
          <scan-code-login v-model="scanCodeLogin"></scan-code-login>
        </div>
      </div>
      <!-- </el-col>
      <el-col :xs="1" :sm="2" :md="2" :lg="4" :xl="6"></el-col> -->
    </el-row>
  </div>
</template>

<script>
import LoginFormByPassword from "./LoginFormByPassword.vue";
import ThirdLogin from "./ThirdLogin.vue";
import ScanCodeLogin from "./ScanCodeLogin.vue";
import LoginFormByMsg from "./LoginFormByMsg.vue";
import { mapGetters } from "vuex";

export default {
  name: "YoungBlue",
  components: {
    LoginFormByPassword,
    ThirdLogin,
    ScanCodeLogin,
    LoginFormByMsg,
  },
  data() {
    return {
      loading: false,
      title: this.$baseTitle,
      loginType: "1", //登录方式 1账号密码 2短信登录 3第三方
      agreement: false,
      scanCodeLogin: "", // 4开启扫码登陆
      screenWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      application: "application/application",
    }),
    passwordLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("password") >= 0
      );
    },
    phoneCodeLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("phone_code") >= 0
      );
    },
    qrcodeLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("qrcode") >= 0
      );
    },
  },
  watch: {
    loginType: {
      handler() {
        this.scanCodeLogin = this.loginType;
      },
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    console.log(this.screenWidth, this.$el.clientWidth, window.innerWidth);
    this.$nextTick(() => {
      // this.screenWidth = this.$el.clientWidth;
    });
  },
  methods: {
    loadingChange(e) {
      this.loading = e;
    },
    handleLogin() {
      if (this.loginType == "1") {
        this.$refs.loginFormByP.handleLogin({
          agreement: this.agreement,
        });
      } else if (this.loginType == "2") {
        this.$refs.loginFormByMsg.handleLoginByMsg({
          agreement: this.agreement,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.el-row,
.gp-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
}
.qz-login-container {
  height: 100vh;
  background: linear-gradient(#1890ff 50%, #ffffff 50%);
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  .qz-header-title {
    height: 80px;
    width: 100%;
    border-radius: 14px 14px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    z-index: 1;
    .qz-header-logo {
      width: 30px;
      height: 39px;
      margin: 0 10px;
      margin-left: 40px;
    }
    .qz-title {
      font-size: 28px;
      font-weight: 500;
      padding-left: 30px;
      color: #ffffff;
      letter-spacing: 0.1em;
      text-shadow: 0 0 10px #2330a3;
      -webkit-text-shadow: 0 0 10px #2a3591;
      -moz-text-shadow: 0 0 10px #2a3591;
      -o-text-shadow: 0 0 10px #2a3591;
    }
  }

  .qz-login-btn {
    display: inherit;
    width: 96%;
    height: 42px;
    margin-top: 5px;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.3em;
    font-size: 15px;
    &:hover {
      opacity: 0.9;
    }
  }

  .qz-login-form {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 4.5vh;
    background-color: #ffffff;
    border-radius: 14px;
    box-shadow: 0 0 5px #aaaaab;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    top: 50%;
    .qz-forget-password {
      width: 100%;
      margin-top: 40px;
      text-align: left;

      .qz-forget-pass {
        width: 129px;
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(92, 102, 240, 1);
      }
    }
    /** 修改tabs下划线样式 */
    ::v-deep {
      .el-tabs__item {
        font-size: 16px;
        &.is-active {
          font-weight: 600;
        }
      }
      .el-tabs__nav-wrap::after {
        background-color: transparent !important;
      }
    }

    &.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      top: 0;
      margin: auto;
    }
    .left-panel {
      //   width: 45%;
      width: 100%;
      //   height: 420px;
      &.disabled {
        opacity: 0.5;
      }
    }
    .right-panel {
      width: 45%;
      height: 420px;
    }
    .line {
      width: 0;
      height: 400px;
      border-right: 2px dotted #cececf;
    }

    &.school {
      // min-width: 600px;
      // width: calc(100% - 122px - 15px);
      /**学院风输入框样式 */
      ::v-deep {
        .el-form-item {
          .qz-svg-container {
            display: none !important;
          }
          .el-input {
            border: #1890ff 1px solid;
            input {
              padding-left: 15px;
              height: 50px;
            }
          }
          .verifiy-code-input {
            width: calc(100% - 122px - 15px);
            min-width: 100px;
          }
          .qz-code-img {
            position: relative !important;
            display: inline-block !important;
            margin-left: 15px;
            vertical-align: middle;
            top: 0;
          }
        }
      }
    }
  }

  .qz-tips {
    margin-bottom: 10px;
    font-size: $base-font-size-default;
    color: $base-color-white;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .qz-title-container {
    position: relative;

    .qz-title {
      margin: 0 auto 40px auto;
      font-size: 34px;
      font-weight: bold;
      color: $base-color-blue;
      text-align: center;
    }
  }

  ::v-deep {
    .el-form-item {
      margin: 10px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      &__content {
        min-height: $base-input-height;
        line-height: $base-input-height;
      }

      &__error {
        position: absolute;
        top: 100%;
        left: 18px;
        font-size: $base-font-size-small;
        line-height: 18px;
        color: $base-color-red;
      }
    }

    .el-input {
      box-sizing: border-box;

      input {
        height: 58px;
        padding-left: 95px;
        font-size: $base-font-size-default;
        line-height: 58px;
        color: $base-font-color;
        /* background: #f6f4fc; */
        border: 0;
        caret-color: $base-font-color;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
.qz-remember-password {
  margin-top: 20px;
  display: flex;
  margin-left: 10px;
}

.protocol {
  text-decoration: underline;
  color: #49b7fb;
}

.forgot-password {
  font-size: 13px;
  color: #888a88;
  margin-top: 20px;
  text-align: center;
  a {
    text-decoration: underline;
    color: #198afa;
  }
}

.code-content {
  margin-top: 30px;
  text-align: center;
  padding-bottom: 40px;
  .code-img {
    width: 164px;
    height: 164px;
  }
  .text {
    color: #000;
    font-size: 16px;
    margin-top: 20px;
    letter-spacing: 1px;
  }
}
</style>
