<template>
  <div
    class="qz-login-container"
    style=""
    :style="{
      background: 'url(' + login_bg_cover + ') 35% center fixed no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <el-row class="h-100">
      <el-col :xs="0" :sm="24" :md="11" :lg="14" :xl="14">
        <div class="qz-login-left"></div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="9"
        :xl="9"
        ass="h-100"
        class="my-auto"
      >
        <div class="qz-header-title">
          <div class="qz-title">{{ title }}</div>
        </div>
        <div class="qz-login-form">
          <el-tabs v-model="loginType">
            <el-tab-pane v-if="passwordLogin" label="账号密码" name="1">
              <login-form-by-password
                ref="loginFormByP"
                @captcha_valueEnter="handleLogin"
                @change="loadingChange"
              >
              </login-form-by-password>
            </el-tab-pane>
            <el-tab-pane v-if="phoneCodeLogin" label="短信登录" name="2">
              <login-form-by-msg ref="loginFormByMsg"></login-form-by-msg>
            </el-tab-pane>
          </el-tabs>
          <el-button
            v-if="passwordLogin || phoneCodeLogin || loginType == 4"
            :loading="loading"
            class="qz-login-btn"
            type="primary"
            round
            @click="handleLogin"
          >
            登录
          </el-button>

          <!-- <div v-show="(passwordLogin || phoneCodeLogin) && loginType != '4'" class="qz-remember-password">
            <el-checkbox v-model="agreement" >
              阅读并同意<a class="protocol">学工系统用户协议</a>及<a
                class="protocol"
                >用户隐私协议</a
              >
            </el-checkbox>
          </div> -->

          <div
            v-if="application.enable_find_password == '1'"
            class="forgot-password"
          >
            忘记密码？<a>这里找回</a>
          </div>

          <!-- <third-login></third-login>

          <scan-code-login></scan-code-login> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="1" :lg="1" :xl="1"></el-col>
    </el-row>
  </div>
</template>

<script>
import LoginFormByPassword from "./LoginFormByPassword.vue";
import ThirdLogin from "./ThirdLogin.vue";
import ScanCodeLogin from "./ScanCodeLogin.vue";
import LoginFormByMsg from "./LoginFormByMsg.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LoginFormByPassword,
    ThirdLogin,
    ScanCodeLogin,
    LoginFormByMsg,
  },
  data() {
    return {
      loading: false,
      title: this.$baseTitle,
      loginType: "1", //登录方式 1账号密码 2短信登录 3第三方
      agreement: false,
      login_bg_cover: require("@/assets/login_images/purpleblue_background.jpg"),
    };
  },
  created() {
    if (process.env.VUE_APP_LOGIN_BG) {
      this.login_bg_cover = require(process.env.VUE_APP_LOGIN_BG);
    }
  },
  computed: {
    ...mapGetters({
      application: "application/application",
    }),
    passwordLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("password") >= 0
      );
    },
    phoneCodeLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("phone_code") >= 0
      );
    },
    qrcodeLogin() {
      return (
        this.application.signin_types &&
        this.application.signin_types.indexOf("qrcode") >= 0
      );
    },
  },
  methods: {
    loadingChange(e) {
      this.loading = e;
    },
    handleLogin() {
      if (this.loginType == "1") {
        this.$refs.loginFormByP.handleLogin({
          agreement: this.agreement,
        });
      } else if (this.loginType == "2") {
        this.$refs.loginFormByMsg.handleLoginByMsg({
          agreement: this.agreement,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row,
.gp-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
}
.qz-login-container {
  height: 100vh;
  background: url("~@/assets/login_images/background.jpg") 35% center fixed
    no-repeat;
  background-size: cover;
  position: relative;
  .qz-header-title {
    height: 80px;
    z-index: 1;
    position: relative;
    width: 100%;
    border-radius: 14px 14px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 20%;
    .qz-title {
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      z-index: 5;
      letter-spacing: 0.1em;
      text-shadow: 0 0 10px #2330a3;
      -webkit-text-shadow: 0 0 10px #2a3591;
      -moz-text-shadow: 0 0 10px #2a3591;
      -o-text-shadow: 0 0 10px #2a3591;
    }
    .qz-title-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 10px 10px 0 0;
    }
    .qz-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 10px 10px 0 0;
      /* background-color: rgba(0, 2, 14, 0.5); */
      background-color: rgba(0, 2, 14, 0);
    }
  }

  .qz-title-tips {
    margin-top: 29px;
    font-size: 26px;
    font-weight: 400;
    color: rgba(14, 18, 26, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .qz-login-btn {
    display: inherit;
    width: 280px;
    height: 42px;
    border-radius: 25px;
    margin-top: 5px;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.3em;
    font-size: 15px;
    &:hover {
      opacity: 0.9;
    }
  }

  .qz-login-form {
    position: relative;
    max-width: 100%;
    min-width: 300px;
    margin: 0 5vw 5vw;
    overflow: hidden;
    padding-bottom: 4.5vh;
    background-color: #ffffff;
    border-radius: 14px;
    box-shadow: 0 0 5px 5px #434fc5;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    .qz-forget-password {
      width: 100%;
      margin-top: 40px;
      text-align: left;

      .qz-forget-pass {
        width: 129px;
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(92, 102, 240, 1);
      }
    }
    /** 修改tabs下划线样式 */
    ::v-deep {
      .el-tabs__item {
        font-size: 16px;
        &.is-active {
          font-weight: 600;
        }
      }
      .el-tabs__nav-wrap::after {
        background-color: transparent !important;
      }
    }
  }

  .qz-tips {
    margin-bottom: 10px;
    font-size: $base-font-size-default;
    color: $base-color-white;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .qz-title-container {
    position: relative;

    .qz-title {
      margin: 0 auto 40px auto;
      font-size: 34px;
      font-weight: bold;
      color: $base-color-blue;
      text-align: center;
    }
  }

  ::v-deep {
    .el-form-item {
      margin: 15px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      &__content {
        min-height: $base-input-height;
        line-height: $base-input-height;
      }

      &__error {
        position: absolute;
        top: 100%;
        left: 18px;
        font-size: $base-font-size-small;
        line-height: 18px;
        color: $base-color-red;
      }
    }

    .el-input {
      box-sizing: border-box;

      input {
        height: 58px;
        padding-left: 95px;
        font-size: $base-font-size-default;
        line-height: 58px;
        color: $base-font-color;
        /* background: #f6f4fc; */
        border: 0;
        caret-color: $base-font-color;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
.qz-remember-password {
  margin-top: 20px;
  display: flex;
  margin-left: 10px;
}

.protocol {
  text-decoration: underline;
  color: #49b7fb;
}

.forgot-password {
  font-size: 13px;
  color: #888a88;
  margin-top: 20px;
  text-align: center;
  a {
    text-decoration: underline;
    color: #198afa;
  }
}
</style>
